<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body style="height: 1100px; width: 100%">
          <b-card-body>
            <b-form-group label="模版类型" label-for="channelid">
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="TemplateOptions"
              />
            </b-form-group>

            <b-form-group label="选择客服活码 :" label-for="basicInput">
              <span class="align-middle">
                <v-select
                  v-model="qrcode"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="QrcodeOptions"
              /></span>
            </b-form-group>

            <div v-for="(item, index) in list" :key="index" :value="item">
              <b-form-group label="产品链接或者口令 :" label-for="basicInput">
                <div class="dynamic-box">
                  <b-form-input
                    id="basicInput"
                    placeholder="输入链接"
                    v-model="item.name"
                  />
                  <feather-icon
                    v-if="index === 0"
                    icon="PlusIcon"
                    size="18"
                    @click="addInputHandle()"
                  />
                  <feather-icon
                    v-else
                    icon="MinusIcon"
                    size="18"
                    @click="delInputHandle(index)"
                  />
                </div>
                <br />
              </b-form-group>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="addcontent()"
            >
              生成内容
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-modal
          id="modal-login"
          cancel-variant="outline-secondary"
          ok-title="添加"
          cancel-title="关闭"
          centered
          title="请输入微信帐号:"
          @ok="PreviewMessage"
        >
          <b-form>
            <b-form-group>
              <label for="module"></label>
              <b-form-input
                id="module"
                placeholder="微信帐号"
                autocomplete="off"
                v-model="wechatid"
              />
            </b-form-group>
          </b-form>
        </b-modal>
        <b-card
          no-body
          class="border-primary"
          style="height: 1100px; width: 100%"
        >
          <b-card-header
            class="
              d-flex
              justify-content-between
              align-items-center
              pt-75
              pb-25
            "
          >
            <h5 class="mb-0">编辑文章</h5>
            <b-badge variant="light-primary"> Basic </b-badge>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>
                <b-form-group label="标题 :" label-for="basicInput">
                  <span class="align-middle">
                    <b-form-input
                      id="input-lg"
                      size="lg"
                      placeholder="标题"
                      v-model="title1"
                  /></span>
                </b-form-group>
              </li>

              <li>
                <b-form-group label="文章简介 :" label-for="basicInput">
                  <span class="align-middle">
                    <b-form-input
                      id="input-lg"
                      size="lg"
                      placeholder="简介"
                      v-model="desc"
                  /></span>
                </b-form-group>
              </li>

              <li>
                <span class="align-middle">文章内容：</span>
              </li>
              <li class="my-25">
                <span class="align-middle">
                  <vue-ueditor-wrap
                    v-model="content"
                    :config="editorConfig"
                    editor-id="editor-demo-01"
                    class=""
                  ></vue-ueditor-wrap>
                </span>
              </li>
              <li>
                <span class="align-middle">文章内容：</span>
              </li>
              <li>
                <b-form-input
                  id="input-lg"
                  placeholder="文章链接"
                  v-model="shorturl"
                />
              </li>
              <li>
                <div style="float: left">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="addarticle"
                  >
                    草稿
                  </b-button>
                </div>
                <div style="float: left">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    v-b-modal.modal-login
                  >
                    预览
                  </b-button>
                </div>
                <div style="float: left">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="articlesubmit"
                  >
                    发布
                  </b-button>
                </div>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BAlert,
  BLink,
  BCard,
  BImg,
  BMedia,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormInput,
  BCol,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import VueUeditorWrap from "vue-ueditor-wrap";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BMedia,
    BRow,
    BCol,
    BAlert,
    BLink,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    vSelect,
    VueUeditorWrap,
    VuePerfectScrollbar,
    ToastificationContent,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      selected: "",
      tkl: [],
      content: "",
      desc: "",
      qrcode: "",
      mediaId: "",
      shorturl: "",
      wechatid: "",
      title1: "",
      sccheck: false,
      list: [{ name: "" }],
      TemplateOptions: [],
      QrcodeOptions: [],
      editor: null,
      editorConfig: {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: "https://wxscrm.zhebaihui.net/UEditor/",
        // UEDITOR_HOME_URL: "http://192.168.1.12:8080/UEditor/",
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        //serverUrl: "//www.baidu.com/cos",
      },
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        this.$emit("update:visible", val);
      },
    },
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.did = user.did;

    useJwt.templatebycid({ cid: this.cid }).then((res) => {
      console.log("res2", JSON.stringify(res.data.data));
      if (res.data.data != null) {
        const forArr = res.data.data;
        forArr.forEach((item, i) => {
          this.TemplateOptions.push({ title: item.name, value: item.id });
          console.log(
            " this.TemplateOptions",
            JSON.stringify(this.TemplateOptions)
          );
        });
      }
    });

    useJwt.findqrcodebycid({ cid: this.cid }).then((res) => {
      console.log("res", JSON.stringify(res));
      if (res.data.data != null) {
        const forArr = res.data.data;
        forArr.forEach((item, i) => {
          this.QrcodeOptions.push({ title: item.name, value: item.id });
        });
        console.log(" this.QrcodeOptions", JSON.stringify(this.QrcodeOptions));
      }
    });
  },
  methods: {
    handleConfrim(e) {
      e.preventDefault();
      this.$emit("beSure");
    },
    addInputHandle() {
      if (this.list.length < 10) {
        this.list.push({ name: "" });
        console.log(JSON.stringify(this.list));
      }
    },
    addarticle() {
      if (this.title1 == "") {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `请填写标题!`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `请填写标题`,
          },
        });
        return;
      }

      if (this.desc == "") {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `请填写简介!`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `请填写简介`,
          },
        });
        return;
      }
      if (this.sccheck == false) {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `请先生成内容!`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `请先生成内容`,
          },
        });
        return;
      }
      useJwt
        .addarticle({
          title: this.title1,
          desc: this.desc,
          content: this.content,
        })
        .then((res) => {
          console.log("草稿", JSON.stringify(res.data));
          this.mediaId = res.data;
          console.log("this.mediaId", this.mediaId);
          this.$swal({
            icon: "success",
            title: "生成成功!",
            text: "",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
    addcontent() {
      this.tkl = [];
      this.content = "";
      this.list.forEach((itme, i) => {
        if (itme.name != "") {
          this.tkl.push(itme.name);
        }
      });
      if (this.tkl.length == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `请输入产品链接!`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `请输入产品链接`,
          },
        });
        return;
      }
      useJwt
        .addcontent({
          cid: this.cid,
          SpUrl: this.tkl,
          name: this.selected.title,
          activityid: this.qrcode.value,
        })
        .then((res) => {
          console.log("res", JSON.stringify(res));
          this.content = res.data.replace("&nbsp;", "");
          this.sccheck = true;
        });
    },
    articlesubmit() {
      useJwt
        .articlesubmit({
          mediaId: this.mediaId,
        })
        .then((res) => {
          console.log("发布", JSON.stringify(res));
          if (res.data.msg == "success") {
            this.shorturl = res.data.data;
            this.$swal({
              icon: "success",
              title: "发布成功!",
              text: "",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
    PreviewMessage() {
      useJwt
        .PreviewMessage({
          mediaId: this.mediaId,
          wechatid: this.wechatid,
        })
        .then((res) => {
          console.log("以提交预览", JSON.stringify(res));
          if (res.data.msg == "success")
            this.$swal({
              icon: "success",
              title: "请查看微信!",
              text: "",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
        });
    },
    delInputHandle(index) {
      this.list.splice(index, 1);
    },
    handleCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.dynamic-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    font-size: 24px;
    margin-left: 15px;
  }
}
.edui-button.edui-for-135editor .edui-button-wrap .edui-button-body .edui-icon {
  background-image: url("https://static.135editor.com/img/icons/editor-135-icon.png") !important;
  background-size: 85%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>